import React from "react"

import Seo from "../shared_components/seo"

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404 - Page Not Found" />
      <h1>404 - Page Not Found</h1>
    </>
  )
}

export default NotFoundPage
